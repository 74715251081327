import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { BecksInputModule } from 'src/app/library/components/becks-input/becks-input.module';
import { CalculatedValueComponent } from 'src/app/shared/components/calculated-value/calculated-value.component';
import { DividerComponent } from 'src/app/shared/components/divider/divider.component';
import { EnvironmentBadgeComponent } from 'src/app/shared/components/environment-badge/environment-badge.component';
import { ItemsModalFooterComponent } from 'src/app/shared/components/items-form/items-modal-footer/items-modal-footer.component';
import { ItemsModalHeaderComponent } from 'src/app/shared/components/items-form/items-modal-header/items-modal-header.component';
import { PanelComponent } from 'src/app/shared/components/panel/panel.component';
import { RetryComponent } from 'src/app/shared/components/retry/retry.component';
import { SkeletonLineComponent } from 'src/app/shared/components/skeleton-line/skeleton-line.component';
import { SkeletonTableComponent } from 'src/app/shared/components/skeleton-table/skeleton-table.component';
import { MaterialModule } from '../material/material.module';
import { DownloadOutletComponent } from './components/download-outlet/download-outlet.component';
import { ModalComponent } from './components/modal/modal.component';
import { SparklineComponent } from './components/sparkline/sparkline.component';
import { SplitPanelComponent } from './components/split-panel/split-panel.component';
import { SvgComponent } from './components/svg/svg.component';
import { ToggleEditInputComponent } from './components/toggle-edit-input/toggle-edit-input.component';
import { AcreageOverviewComponent } from './containers/acreage-overview/acreage-overview.component';
import { ProductionYearSelectComponent } from './containers/production-year-select/production-year-select.component';
import { QuickLotComponent } from './containers/quick-lot/quick-lot.component';
import { ScenarioLabelComponent } from './containers/scenario-label/scenario-label.component';
import { AppVersionDirective } from './directives/app-version.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { EditModeDirective } from './directives/editable/edit-mode.directive';
import { EditOnEnterDirective } from './directives/editable/edit-on-enter.directive';
import { EditableComponent } from './directives/editable/editable.component';
import { ViewModeDirective } from './directives/editable/view-mode.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { PositiveWholeNumberDirective } from './directives/positiveWholeNumberDirective';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [
    SvgComponent,
    AppVersionDirective,
    ProductionYearSelectComponent,
    QuickLotComponent,
    ModalComponent,
    AcreageOverviewComponent,
    SplitPanelComponent,
    EditModeDirective,
    EditOnEnterDirective,
    ViewModeDirective,
    EditableComponent,
    DownloadOutletComponent,
    SparklineComponent,
    ScenarioLabelComponent,
    ToggleEditInputComponent,
    HasPermissionDirective,
    AutoFocusDirective,
    IntersectionObserverDirective,
    CalculatedValueComponent,
    PositiveWholeNumberDirective,
    ItemsModalHeaderComponent,
    ItemsModalFooterComponent,
    PanelComponent,
    DividerComponent,
    SkeletonLineComponent,
    SkeletonTableComponent,
    RetryComponent,
    EnvironmentBadgeComponent
  ],
  imports: [
    CommonModule,
    BecksInputModule,
    PipesModule,
    MaterialModule,
    InlineSVGModule,
    FlexLayoutModule,
    NgxMatSelectSearchModule,
    VirtualScrollerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    SatPopoverModule
  ],
  exports: [
    MaterialModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    VirtualScrollerModule,
    NgxSliderModule,
    SvgComponent,
    ProductionYearSelectComponent,
    QuickLotComponent,
    ModalComponent,
    AcreageOverviewComponent,
    SplitPanelComponent,
    AppVersionDirective,
    EditableComponent,
    EditModeDirective,
    EditOnEnterDirective,
    ViewModeDirective,
    DownloadOutletComponent,
    SparklineComponent,
    ScenarioLabelComponent,
    ToggleEditInputComponent,
    HasPermissionDirective,
    AutoFocusDirective,
    IntersectionObserverDirective,
    CalculatedValueComponent,
    PositiveWholeNumberDirective,
    ItemsModalFooterComponent,
    ItemsModalHeaderComponent,
    PanelComponent,
    DividerComponent,
    SkeletonLineComponent,
    SkeletonTableComponent,
    RetryComponent,
    EnvironmentBadgeComponent
  ]
})
export class SharedModule {}
